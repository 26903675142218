import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Login from "./scene/Login";
import Register from "./scene/Register";
import Home from "./scene/Home";
import GeneralHome from "./scene/GeneralHome";
import Profile from "./scene/Profile";
import Control from "./scene/Control";
import BoardUser from "./scene/Settings";
import BoardModerator from "./scene/BoardModerator";
import BoardAdmin from "./scene/BoardAdmin";
import AlexaPrivacyPolicy from "./scene/AlexaPrivacyPolicy";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
// import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout(currentUser, navigate));
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      //setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      console.log("currentUser.user.isAdmin: "+currentUser.user.isAdmin);
      setShowAdminBoard(currentUser.user.isAdmin);
      setShowModeratorBoard(false);
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  return (
    <div>
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <Link to={"/GeneralHome"} className="navbar-brand">
          LandLord Automations
        </Link>
        <div className="navbar-nav mr-auto">
          
          {currentUser && (<li className="nav-item">
            <Link to={"/home"} className="nav-link">
              Home
            </Link>
          </li>)}
           {currentUser && (<li className="nav-item">
            <Link to={"/profile"} className="nav-link">
              Profile
            </Link>
          </li>)}

          {currentUser && (
            
            <li className="nav-item">
              <Link to={"/settings"} className="nav-link">
                Settings
              </Link>
            </li>
          )}

          {currentUser && (
            <li className="nav-item">
              <Link to={"/control"} className="nav-link">
                Controls
              </Link>
            </li>
          )}

          {showModeratorBoard && currentUser && (
            <li className="nav-item">
              <Link to={"/mod"} className="nav-link">
                Moderator Board
              </Link>
            </li>
          )}

          {showAdminBoard && currentUser && (
            <li className="nav-item">
              <Link to={"/admin"} className="nav-link">
                Admin Board
              </Link>
            </li>
          )}
        </div>

        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link">
                {currentUser.username}
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={logOut} style={{ cursor: 'pointer' }}>
                LogOut
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                Login
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div className="container mt-3">
        <Routes>
          <Route path="/GeneralHome" element={<GeneralHome />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/control" element={<Control />} />
          <Route path="/settings" element={<BoardUser />} />
          <Route path="/alexaprivacy" element={<AlexaPrivacyPolicy />} />
          <Route path="/mod" element={<BoardModerator />} />
          <Route path="/admin" element={<BoardAdmin />} />
        </Routes>
      </div>

      {/* <AuthVerify logOut={logOut}/> */}
    </div>
  );
};

export default App;
